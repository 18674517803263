import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const NavLink = styled(Link)`
  color: #222;
  font-size: 1rem;
  font-weight: ${props => props.fontWeight || "normal"};
  line-height: 1;
  margin: 0 0.5rem 0 0;
  padding: 0.25rem;
  text-decoration: none;
  &.current-page {
    border-bottom: 2px solid #222;
  }
`

const Header = ({ siteTitle }) => (
  <header
    css={css`
      background: #f1f3e6;
      border-bottom: 1px solid #ddd;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem calc((100vw - 700px) / 2);
      border-radius: 0 0 85% 85% / 10%;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    `}
  >
    <div>
      <h1
        css={css`
          margin: 0;
        `}
      >
        <Link
          fontWeight="bold"
          to="/"
          css={css`
            line-height: 3rem;
            text-decoration: none;
            font-size: 20px;
            color: #666;
          `}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
    <nav>
      {/* <NavLink to="/" activeClassName="current-page">
        Home
      </NavLink>
      <NavLink to="/about" activeClassName="current-page">
        About
      </NavLink> */}
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
